/* #IMPORTS ~~
========================================================================== */
@import './node_modules/bootstrap/scss/functions';
@import './node_modules/bootstrap/scss/variables'; 
@import './node_modules/bootstrap/scss/mixins';
@import './src/scss/_mixins/mixins'; 
@import './src/scss/_themes/_modules/variables';
@import './src/scss/_themes/_modules/_placeholders';


/* #GLOBAL IMPORTS
========================================================================== */
/*@import '_imports/_global-import';*/

/* #FRAMEWORK - Structure and layout files. (**DO NOT** change order)
                DOC: you can disable unused _modules
========================================================================== */
/* contains root variables to be used with css (see docs) */
/* html and body base styles */
@import './src/scss/_themes/_modules/_body';

@import './src/scss/_themes/_modules/_page-header';
@import './src/scss/_themes/_modules/_page-logo';
@import './src/scss/_themes/_modules/_page-search';
@import './src/scss/_themes/_modules/_dropdown-icon-menu';
@import './src/scss/_themes/_modules/_dropdown-notification';
@import './src/scss/_themes/_modules/_dropdown-app-list';
@import './src/scss/_themes/_modules/_header-function-fixed';
@import './src/scss/_themes/_modules/_left-panel';
@import './src/scss/_themes/_modules/_nav';
@import './src/scss/_themes/_modules/_nav-listfilter';
@import './src/scss/_themes/_modules/_nav-info-card';
@import './src/scss/_themes/_modules/_nav-function-top';
@import './src/scss/_themes/_modules/_nav-function-minify';
@import './src/scss/_themes/_modules/_nav-footer';
@import './src/scss/_themes/_modules/_page-wrapper';
@import './src/scss/_themes/_modules/_page-heading';
@import './src/scss/_themes/_modules/_page-footer';
@import './src/scss/_themes/_modules/_page-components-accordion';
@import './src/scss/_themes/_modules/_page-components-alerts';
@import './src/scss/_themes/_modules/_page-components-badge';
//@import './src/scss/_themes/_modules/_page-components-breadcrumb';
@import './src/scss/_themes/_modules/_page-components-buttons';
@import './src/scss/_themes/_modules/_page-components-cards';
@import './src/scss/_themes/_modules/_page-components-carousel';
@import './src/scss/_themes/_modules/_page-components-dropdowns';
@import './src/scss/_themes/_modules/_page-components-messanger';
@import './src/scss/_themes/_modules/_page-components-modal';
@import './src/scss/_themes/_modules/_page-components-panels';
@import './src/scss/_themes/_modules/_page-components-popovers';
@import './src/scss/_themes/_modules/_page-components-shortcut';

@import './src/scss/_themes/_modules/_helpers';
@import './src/scss/_themes/_modules/_misc';
@import './src/scss/_themes/_modules/_hack';
@import './src/scss/_themes/_modules/_responsive';
@import './src/scss/_themes/_modules/_forms';
@import './src/scss/_themes/_modules/_settings-demo';
@import './src/scss/_themes/_modules/_settings-demo-incompatiblity-list';
@import './src/scss/_themes/_modules/_settings-demo-theme-colors';


@import './src/scss/_themes/_modules/_mod-nav-accessibility';
@import './src/scss/_themes/_modules/_colors';

@import './src/scss/_themes/_modules/_custom-bootstrap-varients';

//we import plugins here
@import './src/scss/_themes/_plugins/_plugins-chartist';