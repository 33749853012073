//bootstrap button colors
@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}

//border
@each $color, $value in $theme-colors {
  .border-#{$color} {
    border-color: $value !important;
  }
}

// Typography
@each $color, $value in $theme-colors {
  @include text-emphasis-variant(".text-#{$color}", $value);
}

//bg varient
@each $color, $value in $theme-colors {
  @include bg-variant(".bg-#{$color}", $value);
}

@if $enable-gradients {
  @each $color, $value in $theme-colors {
    @include bg-gradient-variant(".bg-gradient-#{$color}", $value);
  }
}

//root colors
:root {
  @each $theme-colors, $value in $theme-colors {
    --theme-#{$theme-colors}: #{$value};
  }

  @each $theme-colors-extended, $value in $theme-colors-extended {
    --theme-#{$theme-colors-extended}: #{$value};
  }
}